import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DynamicImage, LoadingPage, PageContainer, Seo } from 'components';
import Header from 'components/Header';
import EmailForm from './components/EmailForm';
import { tablet, useQuery } from 'styles/breakpoints';
import { Analytics } from 'apis/Analytics';
import { createLead, updateQuizAnswers, updateUser } from 'state/user/effects';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Text from 'components/Text';
import { usePageView, useQuizData, useTrackingQueryParams } from 'utils/hooks';
import { Events } from 'utils/events';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';
import { leadLanguage } from 'utils/functions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 3rem 1rem 6rem 1rem;
  background: #fcf7f1;
  @media ${tablet} {
    padding: 2.25rem 1rem;
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 28.75rem;
`;

const Subtitle = styled(Text)`
  margin-top: 1.5rem;
`;

const Footer = styled(Text)`
  max-width: 28.75rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1rem;
  @media ${tablet} {
  }
`;

const ImageContainer = styled.div`
  margin: 4rem 0rem;
  width: 16rem;
  height: 16rem;

  @media ${tablet} {
    width: 7.5rem;
    height: 7.5rem;
  }
`;

const EmailFormStyled = styled(EmailForm)`
  margin: 1rem 0.5rem;
`;

const Title = styled(Text)`
  color: #020124;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 1.75rem */
  letter-spacing: -0.02rem;
  width: 100%;
`;

const Email: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const trackingParams = useTrackingQueryParams();

  const { isTablet } = useQuery();

  const { quiz_answers, user, geolocation, user_ip } = useSelector(
    (state: AppState) => state.user,
  );
  const config = useSelector((state: AppState) => state.config);
  const variant = config?.variant;

  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main-bw';

  const dispatch = useDispatch();

  const { goToCheckout, goToResults } = useRouter();

  usePageView({
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  const handleNextRoute = () => {
    if (variant === 'email-first') {
      return location.search.includes('tst3')
        ? goToCheckout({ discount: false })
        : goToResults();
    }
    goToCheckout({ discount: false });
  };

  const handleGetResults = async (email: string) => {
    setError('');

    setLoading(true);

    Tracking.trackCTAButton(location.pathname);

    if (!user?.email || email !== user.email) {
      dispatch(dispatch(updateUser({ email, password: '' })));
    }

    quiz_answers.quiz = quiz;

    try {
      const code =
        (await dispatch(
          createLead({
            email,
            funnel: quiz,
            quiz_answers: { ...quiz_answers, ...trackingParams },
            language: leadLanguage(quiz),
          }),
        )) ?? '';
      // cliendCodeToUrl(code);
      dispatch(updateQuizAnswers(trackingParams));
      const userState = normalizeStates(
        geolocation?.iso_country || '',
        geolocation?.iso_state || '',
      );

      Tracking.leadCreated(
        code,
        email,
        geolocation?.iso_country,
        quiz_answers?.gender ? quiz_answers?.gender[0] : null,
        userState,
        user_ip,
      );

      handleNextRoute();
    } catch (error) {
      setError(error?.response?.data?.errors?.email ?? '');

      setLoading(false);
    }
  };

  const data = useQuizData('email');

  useEffect(() => {
    setTimeout(() => {
      window.scroll({ top: -1, left: 0, behavior: 'smooth' });
    }, 10);
  }, [window.location.href, location.pathname]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <PageContainer with100vh>
        <Container>
          <Seo />
          <Header logoVariant="center" color="colorLight" />
          <ContentContainer>
            <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
            <EmailFormStyled
              loading={loading}
              error={error}
              onSubmit={handleGetResults}
              continueBtnTitle={data?.continueBtnTitle}
              placeholder={data?.inputPlaceholder}
              label={data?.inputLabel}
            />
            {data.img && (
              <ImageContainer>
                <DynamicImage
                  src={data.img}
                  alt={data.alt}
                  width="100%"
                  height="100%"
                />
              </ImageContainer>
            )}
            <Footer textAlign="left">{data?.footer}</Footer>
          </ContentContainer>
        </Container>
      </PageContainer>
    </>
  );
};

export default Email;
