import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';

interface EmailFormProps {
  loading: boolean;
  error: string;
  placeholder: string;
  continueBtnTitle: string;
  onSubmit: (email: string) => void;
  label: string;
}

const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  max-width: 28.75rem;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Submit = styled(PrimaryButton)`
  height: 3rem;
  width: 100%;
  border-radius: 0.5rem;
`;

const InputStyled = styled.input`
  border: none;
  background-color: ${({ theme }) => theme.colors.light0};
  border-radius: 0.5rem;
  outline: none;
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 4rem;
  padding: 0 0.91469rem;
  margin-bottom: 2.5rem;
  width: 100%;
  ::placeholder {
    color: #717171;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px white inset !important;
  }
`;

const Error = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1rem;
  color: red;
  margin: 0.5rem 0;
`;

const InputLabel = styled.div`
  color: var(--Black, #000);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 0.5rem;
  width: 100%;
`;

const EmailForm: FC<EmailFormProps> = ({
  loading,
  error,
  label,
  placeholder = 'Enter your email',
  continueBtnTitle = 'Continue',
  onSubmit,
  ...props
}) => {
  const [email, setEmail] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(email.toLowerCase());
  };

  return (
    <Container onSubmit={handleSubmit} {...props}>
      <InputLabel>{label}</InputLabel>
      <ContentContainer>
        <InputStyled
          name="email"
          type="email"
          placeholder={placeholder}
          disabled={loading}
          required
          onChange={handleChange}
        />
        {error ? <Error>{error}</Error> : null}
      </ContentContainer>
      <Submit
        disabled={loading}
        loading={loading}
        type="submit"
        cornerVariant="round"
      >
        {continueBtnTitle}
      </Submit>
    </Container>
  );
};

export default EmailForm;
